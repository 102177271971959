import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  PRINT_JOB_TAG_LIST,
  PRINT_JOB_STATUS_LIST,
  CREATE_PRINT_JOB_TAG,
  DELETE_PRINT_JOB_TAG,
  CHANGE_PRINTJOB_STATUS,
  PRINT_JOB_FILTER_OPTIONS_CONFIG,
  PRINT_JOB_EVENT_HISTORY,
  BUILD_AND_DOWNLOAD_PLATES,
  BUILD_AND_UPLOAD_PLATES,
  ADD_BULK_PRINT_JOB_TAG,
  RESEND_STOCK_ITEM,
  GET_STOCKABLE_PRODUCTS_BY_PRINTJOB,
  REMOVE_BULK_PRINT_JOB_TAG,
  GET_ORDER_CANCELLATION_REASONS,
  REPRINT_PLATES,
  UPDATE_PACKAGE_DISPATCH_DATE,
} from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import {
  BuildAndDownloadPlatesResponseData,
  BuildAndUploadPlatesResponseData,
  IChangeStatusParams,
  IPrintJobStatusChangeMessage,
  IFilterOptionConfigResponse,
  IPrintJobHistoryEvent,
  ResendStockItemRequest,
  BulkTagsRequest,
  BulkTagsResultItem,
  ReprintPlatesRequest,
  ReprintPlatesResponse,
  UpdatePackageDispatchDateRequest,
  UpdatePackageDispatchDateResponse,
} from './Types';

export const PrintJobApi = createApi({
  reducerPath: 'PrintJobApi',
  tagTypes: ['PrintJob'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getPrintJobTags: builder.query({
      query: (data) => ({
        url: PRINT_JOB_TAG_LIST,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'PrintJob', id: 'LIST' }],
    }),
    getPrintJobStatuses: builder.query({
      query: (data) => ({
        url: PRINT_JOB_STATUS_LIST,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'PrintJob', id: 'LIST' }],
    }),
    createTag: builder.mutation({
      query: (data) => ({
        url: CREATE_PRINT_JOB_TAG,
        method: 'POST',
        body: data,
      }),
    }),
    deleteTag: builder.mutation({
      query: (data) => ({
        url: DELETE_PRINT_JOB_TAG,
        method: 'POST',
        body: data,
      }),
    }),
    addBulkTags: builder.mutation<BulkTagsResultItem[], BulkTagsRequest>({
      query: (data) => ({
        url: ADD_BULK_PRINT_JOB_TAG,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: { tagsAddResponses: BulkTagsResultItem[] } }) =>
        resp?.data?.tagsAddResponses || [],
    }),
    removeBulkTags: builder.mutation<BulkTagsResultItem[], BulkTagsRequest>({
      query: (data) => ({
        url: REMOVE_BULK_PRINT_JOB_TAG,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: { tagsRemoveResponses: BulkTagsResultItem[] } }) =>
        resp?.data?.tagsRemoveResponses || [],
    }),
    changeStatus: builder.mutation<IPrintJobStatusChangeMessage[], IChangeStatusParams>({
      query: (data) => ({
        url: CHANGE_PRINTJOB_STATUS,
        method: 'POST',
        body: data,
      }),
      transformResponse: ({ data }) => {
        return data?.printJobStatus || [];
      },
    }),
    getMPJfilterOptionsConfiguration: builder.query<IFilterOptionConfigResponse, null>({
      query: () => ({
        url: PRINT_JOB_FILTER_OPTIONS_CONFIG,
        method: 'GET',
      }),
      transformResponse: (resp: { data: IFilterOptionConfigResponse }) => {
        return resp.data || {};
      },
      keepUnusedDataFor: 86400, // one day
    }),
    getPrintJobHistory: builder.query<IPrintJobHistoryEvent[], { printJobId: string; isPartnerApp?: boolean }>({
      query: ({ printJobId, isPartnerApp }) => ({
        url: PRINT_JOB_EVENT_HISTORY,
        method: 'POST',
        body: { printJobId, isPartnerApp },
      }),
      providesTags: () => [{ type: 'PrintJob', id: 'HISTORY_LIST' }],
      transformResponse: (resp: { data: { eventHistory: IPrintJobHistoryEvent[] } }) => {
        return resp.data?.eventHistory || [];
      },
    }),
    buildAndDownloadPlates: builder.mutation<BuildAndDownloadPlatesResponseData, string[]>({
      query: (printJobIds) => ({
        url: BUILD_AND_DOWNLOAD_PLATES,
        method: 'POST',
        body: { printJobIdList: printJobIds, forceServiceName: 'IS' },
      }),
      transformResponse: ({ data }) => {
        return data;
      },
    }),
    triggerBuildAndUploadPlates: builder.mutation<
      BuildAndUploadPlatesResponseData,
      { printJobIdList: string[]; comment: string; forceBuild: boolean }
    >({
      query: (data) => ({
        url: BUILD_AND_UPLOAD_PLATES,
        method: 'POST',
        body: data,
      }),
      transformResponse: ({ data }) => {
        return data;
      },
    }),
    reprintPlates: builder.mutation<ReprintPlatesResponse, ReprintPlatesRequest>({
      query: (data) => ({
        url: REPRINT_PLATES,
        method: 'POST',
        body: data,
      }),

      invalidatesTags: [{ type: 'PrintJob', id: 'HISTORY_LIST' }],
    }),
    resendStockItem: builder.mutation<{ printJobId: number }, ResendStockItemRequest>({
      query: (data) => ({
        url: RESEND_STOCK_ITEM,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: { printJobId: number } }) => {
        return resp.data;
      },
    }),
    getStockableProductsByPrintJobId: builder.query<{ productUid: string; alias: string }[], string>({
      query: (printJobId) => ({
        url: GET_STOCKABLE_PRODUCTS_BY_PRINTJOB,
        method: 'POST',
        body: { printJobId },
      }),
      transformResponse: (resp: { data: { product: { productUid: string; alias: string }[] } }) => {
        return resp.data?.product || [];
      },
    }),
    getOrderCancellationReasons: builder.query<{ uid: string; label: string }[] | undefined, null>({
      query: () => ({
        url: GET_ORDER_CANCELLATION_REASONS,
        method: 'GET',
      }),
      keepUnusedDataFor: 86400, // 1-day in seconds
      transformResponse: (resp: { data: { cancellationReason: { uid: string; label: string }[] } }) => {
        return resp.data?.cancellationReason || [];
      },
    }),

    updatePackageDispatchDate: builder.mutation<UpdatePackageDispatchDateResponse, UpdatePackageDispatchDateRequest>({
      query: (data) => ({
        url: UPDATE_PACKAGE_DISPATCH_DATE,
        method: 'POST',
        body: data,
      }),

      invalidatesTags: [{ type: 'PrintJob', id: 'HISTORY_LIST' }],
    }),
  }),
});

export const {
  useGetPrintJobTagsQuery,
  useGetPrintJobStatusesQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
  useAddBulkTagsMutation,
  useRemoveBulkTagsMutation,
  useChangeStatusMutation,
  useGetMPJfilterOptionsConfigurationQuery,
  useGetPrintJobHistoryQuery,
  useBuildAndDownloadPlatesMutation,
  useTriggerBuildAndUploadPlatesMutation,
  useReprintPlatesMutation,
  useResendStockItemMutation,
  useGetStockableProductsByPrintJobIdQuery,
  useGetOrderCancellationReasonsQuery,
  useUpdatePackageDispatchDateMutation,
} = PrintJobApi;
