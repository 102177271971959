import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import {
  KEYCLOAK_SCOPE_MANAGE_FILE_UPLOAD_READ,
  KEYCLOAK_SCOPE_MANAGE_SLA_READ,
  KEYCLOAK_ROLE_PRODUCTION_ADMIN,
  KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT,
  KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
  KEYCLOAK_ROLE_ROUTING_READ_ALL,
} from 'config/KeycloackScopes';

const ProtectedRoute = lazy(() => import('core/ProtectedRoute'));
const PartnerDashboard = lazy(() => import('./PartnerDashboard'));
const AgreementManagement = lazy(() => import('./AgreementManagement/index'));
const ApparelManagement = lazy(() => import('./ApparelManagement'));
const ApparelManagementSetupNewSKUs = lazy(
  () => import('./ApparelManagement/containers/SelectManufacturerSkuContainer'),
);
const WorkflowConfiguration = lazy(() => import('./WorkflowConfiguration'));
const ProductionTime = lazy(() => import('./ProductionTime'));
const DeliveryTime = lazy(() => import('./DeliveryTime'));
const FTPandConnectConfig = lazy(() => import('./FTPandConnect'));
const EditFTPConfig = lazy(() => import('./FTPandConnect/containers/EditFTPConfig'));
const EditAPIConfig = lazy(() => import('./FTPandConnect/containers/EditAPIConfig'));
const AddressContact = lazy(() => import('./AddressContact/index'));
const PackagingErrorRate = lazy(() => import('./PackagingErrorRate'));
const EditContactDetails = lazy(() => import('./AddressContact/containers/EditContactDetails'));
const CreateNewContact = lazy(() => import('./AddressContact/containers/CreateNewContact'));
const FileUploadScheduler = lazy(() => import('./FileUploadScheduler/index'));
const AdditionalUploads = lazy(() => import('./FileUploadScheduler/GelatoInternalView/AdditionalUploads'));
const FileUploadSchedulerV2 = lazy(() => import('./FileUploadSchedulerV2/index'));
const AdditionalUploadsV2 = lazy(() => import('./FileUploadSchedulerV2/GelatoInternalView/AdditionalUploads'));
const ProductionDays = lazy(() => import('./ProductionDays/index'));
const AddNewProductionDay = lazy(() => import('./ProductionDays/containers/AddNewProductionDay'));
const EditProductionDay = lazy(() => import('./ProductionDays/containers/EditProductionDay'));
const RejectProductionDay = lazy(() => import('./ProductionDays/containers/RejectProductionDay'));
const CapacityManagement = lazy(() => import('./CapacityManagement'));
const StockedItems = lazy(() => import('./StockItems'));
const AllPartners = lazy(() => import('./AllPartners'));

const ACCESS_SCOPES = [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER];

const routes = [
  <ProtectedRoute
    path="/print-house-management/:printHouse/partner-dashboard"
    component={PartnerDashboard}
    key="PartnerDashboard"
    scopes={ACCESS_SCOPES}
    title="Partner Dashboard"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/agreement-management"
    component={AgreementManagement}
    key="AgreementManagement"
    scopes={[KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT]}
    title="Pricing Models Management"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/apparel-management/setup-new-skus"
    component={ApparelManagementSetupNewSKUs}
    key="ApparelManagemenSetupNewSKUs"
    scopes={ACCESS_SCOPES}
    title="Apparel Management - Setup New SKUs"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/apparel-management"
    component={ApparelManagement}
    key="ApparelManagement"
    scopes={ACCESS_SCOPES}
    title="Apparel Management"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/workflow-configuration"
    component={WorkflowConfiguration}
    key="WorkflowConfigurationn"
    scopes={ACCESS_SCOPES}
    title="Workflow Configuration"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/sla-configuration"
    component={ProductionTime}
    key="sla-config"
    scopes={[...ACCESS_SCOPES, KEYCLOAK_SCOPE_MANAGE_SLA_READ]}
    title="SLA Configuration"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/delivery-time"
    component={DeliveryTime}
    key="DeliveryTime"
    scopes={ACCESS_SCOPES}
    title="Partner Specific Delivery Time Padding"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/ftp-connect-config/edit-ftp-config"
    component={EditFTPConfig}
    key="EditFTPConfig"
    scopes={ACCESS_SCOPES}
    title="Edit FTP Configuration"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/ftp-connect-config/edit-api-config"
    component={EditAPIConfig}
    key="EditAPIConfig"
    scopes={ACCESS_SCOPES}
    title="Edit API Configuration"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/ftp-connect-config"
    component={FTPandConnectConfig}
    key="FTPandConnectConfig"
    scopes={ACCESS_SCOPES}
    title="FTP & Connect Config Tool"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/address-contact/create-contact"
    component={CreateNewContact}
    key="CreateNewContact"
    scopes={ACCESS_SCOPES}
    title="New Contact"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/address-contact/edit-contact/:id"
    component={EditContactDetails}
    key="EditContactDetails"
    scopes={ACCESS_SCOPES}
    title="Edit Contact"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/address-contact"
    component={AddressContact}
    key="AddressContact"
    scopes={ACCESS_SCOPES}
    title="Partner Details"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/packaging-error-rate"
    component={PackagingErrorRate}
    key="PackagingErrorRate"
    scopes={ACCESS_SCOPES}
    title="Packaging Error Rate"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/create-contact"
    component={CreateNewContact}
    key="CreateNewContact"
    scopes={ACCESS_SCOPES}
    title="New Contact"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/file-upload/additional-uploads"
    component={AdditionalUploads}
    key="AdditionalUploads"
    scopes={ACCESS_SCOPES}
    title="File Upload Scheduler - Additional Uploads"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/file-upload"
    component={FileUploadScheduler}
    key="FileUploadScheduler"
    scopes={[...ACCESS_SCOPES, KEYCLOAK_SCOPE_MANAGE_FILE_UPLOAD_READ]}
    title="File Upload Scheduler"
  />,

  <ProtectedRoute
    path="/print-house-management/:printHouse/file-upload-v2/additional-uploads"
    component={AdditionalUploadsV2}
    key="AdditionalUploads"
    scopes={ACCESS_SCOPES}
    title="File Upload Scheduler - Additional Uploads v2"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/file-upload-v2"
    component={FileUploadSchedulerV2}
    key="FileUploadScheduler"
    scopes={[...ACCESS_SCOPES, KEYCLOAK_SCOPE_MANAGE_FILE_UPLOAD_READ]}
    title="File Upload Scheduler v2"
  />,

  <ProtectedRoute
    path="/print-house-management/:printHouse/production-days"
    component={ProductionDays}
    key="ProductionDays"
    scopes={ACCESS_SCOPES}
    title="Production Days"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/add-new-production-day"
    component={AddNewProductionDay}
    key="UpdateProductionDays"
    scopes={ACCESS_SCOPES}
    title="Production Days - Add Production Day"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/edit-production-day/:id"
    component={EditProductionDay}
    key="EditProductionDay"
    scopes={ACCESS_SCOPES}
    title="Production Days - Edit Production Day"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/reject-production-day/:id"
    component={RejectProductionDay}
    key="RejectProductionDay"
    scopes={ACCESS_SCOPES}
    title="Production Days - Reject Production Day"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/capacity-management"
    component={CapacityManagement}
    key="CapacityManagement"
    scopes={[KEYCLOAK_ROLE_ROUTING_READ_ALL]}
    title="Capacity Management"
  />,
  <ProtectedRoute
    path="/print-house-management/:printHouse/stock-items"
    component={StockedItems}
    key="StockedItems"
    scopes={ACCESS_SCOPES}
    title="Stocked Items"
  />,
  <Redirect
    key="sla-redirect"
    path="/print-house-management/:printHouse/production-time"
    to="/print-house-management/:printHouse/sla-configuration"
  />,
  <Redirect
    key="phmt-index"
    path="/print-house-management/:printHouse"
    to="/print-house-management/:printHouse/address-contact"
  />,
  <ProtectedRoute
    path="/print-house-management"
    component={AllPartners}
    key="AllPartners"
    scopes={ACCESS_SCOPES}
    title="Manage Partners"
  />,
];
export default routes;
