import { configureStore } from '@reduxjs/toolkit';

import { IS_LIVE } from 'config/Environment';

import Authentication from './Authentication/Authentication';
import PrintHouse from './PrintHouse/PrintHouse';
import Configuration from './Configuration/ConfigurationSlice';
import { ConfigurationApi } from './Configuration/ConfigurationApi';
import SelectPrintHouse from './SelectPrintHouse/SelectPrintHouse';

import { PrintHouseApi } from './PrintHouse/PrintHouseApi';
import { FtpandApiConfigApi } from './FtpandApiConfig/FtpandApiConfigApi';
import { FtpStatusApi } from './FtpandApiConfig/FtpStatusApi';
import { UpdateTokenOnExpired } from './Services/UpdateTokenOnExpired';
import { FileUploadSchedulerApi } from './FileUploadScheduler/FileUploadSchedulerApi';
import { FileUploadSchedulerV2Api } from './FileUploadSchedulerV2/FileUploadSchedulerApi';
import { ProductionTimeAPI } from './ProductionTime/ProductionTimeAPI';
import { DeliveryTimeAPI } from './DeliveryTime/DeliveryTimeAPI';
import { ProductionCapabilityAPI } from './ProductionCapability/ProductionCapabilityAPI';
import { AuditLogsApi } from './AuditLogs/AuditLogsApi';
import { NewsFeedApi } from './NewsFeed/NewsFeedApi';

import ProductionDays from './ProductionDays/ProductionDays';
import { ProductionDaysApi } from './ProductionDays/ProductionDaysApi';

import ProductList from './ProductsList/ProductList';
import { ProductsListAPI } from './ProductsList/ProductsListAPI';
import Toast from './Toast/Toast';
import { PrintPlatesApi } from './PrintPlates/PrintPlatesApi';
import { ImpositionServicePrintPlatesApi } from './PrintPlates/ImpositionServicePrintPlatesApi';

import DashboardDataList from './Dashboard/Dashboard';
import { DashboardAPI } from './Dashboard/DashboardAPI';
import UsersSlice from './Users/UsersSlice';
import { UsersAPI } from './Users/UsersApi';
import ApparelManagementSlice from './ApparelManagement/ApparelManagementSlice';
import NewsFeed from './NewsFeed/NewsFeed';
import { ShipmentMethodApi } from './ShipmentMethod/ShipmentMethodApi';
import ShipmentMethodSlice from './ShipmentMethod/ShipmentMethodSlice';
import { PackageShipmentMethodsApi } from './PackageShipmentMethods/PackageShipmentMethodsApi';
import { PrintJobApi } from './PrintJob/PrintJobApi';
import PrintJobSlice from './PrintJob/PrintJobSlice';
import OrderSlice from './Order/OrderSlice';
import { OrderApi } from './Order/OrderApi';
import { PackageApi } from './Package/PackageApi';
import { V1PackageApi } from './Package/V1PackageApi';
import { ApparelManagementApi } from './ApparelManagement/ApparelManagementApi';
import { AgreementManagementApi } from './AgreementManagement/AgreementManagementApi';
import WorkflowConfiguration from './WorkflowConfiguration/WorkflowConfiguration';
import { WorkflowConfigurationApi } from './WorkflowConfiguration/WorkflowConfigurationApi';
import ApparelHeatmapSlice from './ApparelHeatMap/ApparelHeatmapSlice';
import ProductCatalog from './ProductCatalog/ProductCatalog';
import { ProductCatalogAPI } from './ProductCatalog/ProductCatalogAPI';
import { ApparelHeatmapApi } from './ApparelHeatMap/ApparelHeatmapAPI';
import printHouseIdentityContact from './PrintHouseIdentityContact/printHouseIdentityContact';
import { printHouseIdentityContactApi } from './PrintHouseIdentityContact/printHouseIdentityContactApi';
import ContactNotifications from './ContactNotifications/ContactNotifications';
import { ContactNotificationsApi } from './ContactNotifications/ContactNotificationsApi';
import { OrderIssuesApi } from './OrderIssues/OrderIssuesApi';
import { PromiseInfoApi } from './Promise/PromiseInfoApi';
import { ClusterApi } from './Clusters/ClusterAPI';
import { ApparelPricingApi } from './ApparelPricing/ApparelPricingApi';
import { ApparelAvailabilityApi } from './ApparelAvailability/ApparelAvailabilityApi';
import ApparelAvailabilitySlice from './ApparelAvailability/ApparelAvailabilitySlice';
import { ProductionSummaryAPI } from './ProductionSummary/ProductionSummaryAPI';
import { ErrorManagementAPI } from './ErrorManagement/ErrorManagementAPI';
import { TranslationsAPI } from './Translations/TranslationsAPI';
import TranslationsSlice from './Translations/TranslationsSlice';
import { StockApi } from './Stock/StockApi';
import { ProductInfoListApi } from './Stock/ProductInfoListApi';
import { MachineApi } from './Machine/MachineApi';
import { PrintPartnerActivityApi } from './PrintPartnerActivity/PrintPartnerActivityApi';
import { StagedRolloutApi } from './StagedRollout/StagedRolloutApi';
import { SyncFeatureTogglesApi } from './SyncFeatureToggles/SyncFeatureTogglesApi';
import { CapacityManagementApi } from './CapacityManagement/CapacityManagementAPI';
import CapacityManagementSlice from './CapacityManagement/CapacityManagement';
import { CommandsApi } from './Commands/CommandsApi';
import { PartnerClientsApi } from './PartnerClients/PartnerClientsApi';
import { CommunicationCenterAPI } from './CommunicationCenter/CommunicationCenterAPI';
import { ErrorSummaryApi } from './ErrorSummary/ErrorSummaryApi';
import { trackingCodeApi } from './TrackingCode/TrackingCodeApi';

export const store = configureStore({
  devTools: !IS_LIVE,
  reducer: {
    auth: Authentication,
    toast: Toast,
    conguration: Configuration,
    printHouse: PrintHouse,
    printHouseIdentityContact: printHouseIdentityContact,
    contactNotifications: ContactNotifications,
    selectPrintHouse: SelectPrintHouse,
    productCatalog: ProductCatalog,
    productionDays: ProductionDays,
    productList: ProductList,
    dashboardDataList: DashboardDataList,
    users: UsersSlice,
    apparelManagement: ApparelManagementSlice,
    newsFeed: NewsFeed,
    shipmentMethod: ShipmentMethodSlice,
    printJob: PrintJobSlice,
    order: OrderSlice,
    workflowConfiguration: WorkflowConfiguration,
    apparelHeatmap: ApparelHeatmapSlice,
    apparelAvailability: ApparelAvailabilitySlice,
    translations: TranslationsSlice,
    capacityManagement: CapacityManagementSlice,
    [ConfigurationApi.reducerPath]: ConfigurationApi.reducer,
    [PrintHouseApi.reducerPath]: PrintHouseApi.reducer,
    [printHouseIdentityContactApi.reducerPath]: printHouseIdentityContactApi.reducer,
    [ContactNotificationsApi.reducerPath]: ContactNotificationsApi.reducer,
    [FtpandApiConfigApi.reducerPath]: FtpandApiConfigApi.reducer,
    [FtpStatusApi.reducerPath]: FtpStatusApi.reducer,
    [ProductionTimeAPI.reducerPath]: ProductionTimeAPI.reducer,
    [DeliveryTimeAPI.reducerPath]: DeliveryTimeAPI.reducer,
    [ProductionCapabilityAPI.reducerPath]: ProductionCapabilityAPI.reducer,
    [ProductCatalogAPI.reducerPath]: ProductCatalogAPI.reducer,
    [FileUploadSchedulerApi.reducerPath]: FileUploadSchedulerApi.reducer,
    [FileUploadSchedulerV2Api.reducerPath]: FileUploadSchedulerV2Api.reducer,
    [AuditLogsApi.reducerPath]: AuditLogsApi.reducer,
    [ProductionDaysApi.reducerPath]: ProductionDaysApi.reducer,
    [ProductsListAPI.reducerPath]: ProductsListAPI.reducer,
    [PrintPlatesApi.reducerPath]: PrintPlatesApi.reducer,
    [ImpositionServicePrintPlatesApi.reducerPath]: ImpositionServicePrintPlatesApi.reducer,
    [DashboardAPI.reducerPath]: DashboardAPI.reducer,
    [NewsFeedApi.reducerPath]: NewsFeedApi.reducer,
    [MachineApi.reducerPath]: MachineApi.reducer,
    [CommandsApi.reducerPath]: CommandsApi.reducer,
    [PrintPartnerActivityApi.reducerPath]: PrintPartnerActivityApi.reducer,
    [StagedRolloutApi.reducerPath]: StagedRolloutApi.reducer,
    [SyncFeatureTogglesApi.reducerPath]: SyncFeatureTogglesApi.reducer,
    [UsersAPI.reducerPath]: UsersAPI.reducer,
    [ShipmentMethodApi.reducerPath]: ShipmentMethodApi.reducer,
    [PackageShipmentMethodsApi.reducerPath]: PackageShipmentMethodsApi.reducer,
    [PrintJobApi.reducerPath]: PrintJobApi.reducer,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [PackageApi.reducerPath]: PackageApi.reducer,
    [V1PackageApi.reducerPath]: V1PackageApi.reducer,
    [ApparelManagementApi.reducerPath]: ApparelManagementApi.reducer,
    [AgreementManagementApi.reducerPath]: AgreementManagementApi.reducer,
    [WorkflowConfigurationApi.reducerPath]: WorkflowConfigurationApi.reducer,
    [ApparelHeatmapApi.reducerPath]: ApparelHeatmapApi.reducer,
    [OrderIssuesApi.reducerPath]: OrderIssuesApi.reducer,
    [PromiseInfoApi.reducerPath]: PromiseInfoApi.reducer,
    [ClusterApi.reducerPath]: ClusterApi.reducer,
    [ApparelPricingApi.reducerPath]: ApparelPricingApi.reducer,
    [ApparelAvailabilityApi.reducerPath]: ApparelAvailabilityApi.reducer,
    [ProductionSummaryAPI.reducerPath]: ProductionSummaryAPI.reducer,
    [ErrorManagementAPI.reducerPath]: ErrorManagementAPI.reducer,
    [TranslationsAPI.reducerPath]: TranslationsAPI.reducer,
    [StockApi.reducerPath]: StockApi.reducer,
    [ProductInfoListApi.reducerPath]: ProductInfoListApi.reducer,
    [CapacityManagementApi.reducerPath]: CapacityManagementApi.reducer,
    [PartnerClientsApi.reducerPath]: PartnerClientsApi.reducer,
    [CommunicationCenterAPI.reducerPath]: CommunicationCenterAPI.reducer,
    [ErrorSummaryApi.reducerPath]: ErrorSummaryApi.reducer,
    [trackingCodeApi.reducerPath]: trackingCodeApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [],
        ignoredActionPaths: [
          'meta.args',
          'payload.keycloak',
          'meta.baseQueryMeta',
          'meta.arg.originalArgs.csvFile',
          'meta.arg.originalArgs.formData',
        ],
        ignoredPaths: ['auth.keycloak'],
      },
    }).concat(
      UpdateTokenOnExpired,
      ConfigurationApi.middleware,
      PrintHouseApi.middleware,
      printHouseIdentityContactApi.middleware,
      ContactNotificationsApi.middleware,
      FtpandApiConfigApi.middleware,
      FtpStatusApi.middleware,
      ProductionTimeAPI.middleware,
      DeliveryTimeAPI.middleware,
      ProductionCapabilityAPI.middleware,
      ProductCatalogAPI.middleware,
      FileUploadSchedulerApi.middleware,
      FileUploadSchedulerV2Api.middleware,
      AuditLogsApi.middleware,
      ProductionDaysApi.middleware,
      ProductsListAPI.middleware,
      PrintPlatesApi.middleware,
      ImpositionServicePrintPlatesApi.middleware,
      DashboardAPI.middleware,
      NewsFeedApi.middleware,
      MachineApi.middleware,
      CommandsApi.middleware,
      PrintPartnerActivityApi.middleware,
      StagedRolloutApi.middleware,
      SyncFeatureTogglesApi.middleware,
      UsersAPI.middleware,
      OrderApi.middleware,
      PackageApi.middleware,
      V1PackageApi.middleware,
      ApparelManagementApi.middleware,
      AgreementManagementApi.middleware,
      WorkflowConfigurationApi.middleware,
      ApparelHeatmapApi.middleware,
      PackageShipmentMethodsApi.middleware,
      ClusterApi.middleware,
      ApparelPricingApi.middleware,
      ShipmentMethodApi.middleware,
      PrintJobApi.middleware,
      trackingCodeApi.middleware,
      PromiseInfoApi.middleware,
      OrderIssuesApi.middleware,
      ApparelAvailabilityApi.middleware,
      ProductionSummaryAPI.middleware,
      ErrorManagementAPI.middleware,
      TranslationsAPI.middleware,
      StockApi.middleware,
      ProductInfoListApi.middleware,
      CapacityManagementApi.middleware,
      PartnerClientsApi.middleware,
      CommunicationCenterAPI.middleware,
      ErrorSummaryApi.middleware,
      trackingCodeApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
