export const GET_ERRORS = '/v1/production';
export const GET_ERROR_DETAILS = '/v1/production'; // /v1/production/{errorID}
export const GET_REASON_TREE = '/v1/reason-tree';
export const UPDATE_PRINT_HOUSE_NOTIFY_FLAG = '/v1/production/notify';
export const UPDATE_PRODUCT_UID = '/v1/production/uid';
export const GET_ERROR_STATUSES = 'v1/production/error-status';
export const GET_FORWARD_USERS = '/v1/production/forward-users';
export const RESEND_CHARGEBACK = '/v1/production/chargeback/resend';
export const TOGGLE_CHARGEBACK_STATUS = '/v1/production/chargeback/toggle';
export const GET_PRODUCTION_ERROR_ATTACHMENTS = '/v1/production/attachments';
export const GET_PACKAGE_ERRORS = '/v1/package';
export const GET_PACKAGE_ERROR_DETAILS = '/v1/package'; // /v1/package/{errorID}
export const REPLY_A_COMMENT = (errorId: string) => `/v1/production/${errorId}/comment`;
export const SEND_PACKAGE_ERROR_TO_PARTNER = '/v1/package/send';
export const GET_PRINT_HOUSE_PRODUCTS = (printHouseUID: string) => `/v1/production/${printHouseUID}/products`;
export const GET_PRODUCT_GROUP_THRESHOLDS = (printHouseUID: string) => `/v1/production/${printHouseUID}/thresholds`;
export const UPDATE_PRODUCT_GROUP_THRESHOLDS = GET_PRODUCT_GROUP_THRESHOLDS; // Uses same URL pattern
export const GET_PACKAGE_ERROR_RATE = '/v2/gnx/package-error-rate';
export const UPLOAD_ERROR_ATTACHMENTS = (errorId: string) => `/v1/production/${errorId}/attachment`;
export const GET_PRODUCTION_ERROR_COMMENTS = '/v1/production/comments';
