import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { UPLOAD_TRACKING_CODES } from 'config/GelatoNetworkEndPoints';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

interface UploadResponse {
  error: {
    code: number;
    message: string;
  };
  data: {
    message: string;
    processed: number;
    saved: number;
    skipped: number;
    failed: number;
  };
}

export const trackingCodeApi = createApi({
  reducerPath: 'trackingCodeApi',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    uploadTrackingCodes: builder.mutation<UploadResponse, { formData: FormData }>({
      query: ({ formData }) => ({
        url: UPLOAD_TRACKING_CODES,
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});

export const { useUploadTrackingCodesMutation } = trackingCodeApi;
