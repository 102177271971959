import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ERROR_SUMMARY_URL } from 'config/DomainsConfig';

import { ErrorSummaryRequestData, ErrorSummaryResponse } from './Types';

export const ErrorSummaryApi = createApi({
  reducerPath: 'ErrorSummaryApi',
  tagTypes: ['error-report', 'errors-list'],
  baseQuery: fetchBaseQuery({baseUrl: ERROR_SUMMARY_URL}),
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getErrorSummary: builder.query<ErrorSummaryResponse, ErrorSummaryRequestData>({
      query: (payload) => ({
        url: `/webhook/api/error-report/v1/production-ui/summary`,
        method: 'GET',
        headers: {
          // TODO: Remove this in phase two, for now leave it hardcoded
          Authorization: 'Bearer tejlsHGDdWHYTit26zYP5afYXbZ8egfBHZyzTPJt6ZcMRsJrRHiLziTeJbSyXUFIMurifD1Prbs1Svfi62OxYh17pUwCIS0HkHz65caooLzdod6UjMvYH61MungF3ozH'
        },
        params: {
          printHouseUid: payload?.printHouseUid,
          dateFrom: payload?.range?.from,
          dateTo: payload?.range?.to
        }
      }),
      transformErrorResponse: (response) => {
        return response.data ?? 'Failed to fetch error summary';
      }
    }),
  }),
});

export const {
  useGetErrorSummaryQuery,
  useLazyGetErrorSummaryQuery
} = ErrorSummaryApi;
